import React from "react"
import Layout from "../src/components/layout"
import styled from "styled-components"
import ReactPlayer from "react-player"
import BlogTemplateColumnData from "../src/components/blogTemplateColumn"

import ImageCover from "../src/components/ImageCover"
import ReactMarkdown from "react-markdown"
import Seo from "../src/components/seo"

const Wrapper = styled.article`
  margin: 0 auto;
  padding: 0 40px 20px;
  .markedContent {
    margin-top: 20px;
  }

  h1 {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 1080px) {
    article {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;
    }
    padding: 0 20px 20px;
    flex-direction: column;
    .mignon {
      max-width: 280px !important;
      width: 100% !important;
      margin-left: 0 !important;
    }
  }
`

const Article = styled.article`
  max-width: "600px";
  overflow-x: hidden;
  img {
    max-height: 600px;

    filter: drop-shadow(0 0 2px #0003);
    border-radius: 5px;
    overflow: hidden;
    object-fit: contain;
  }
  margin-top: 2rem;
  strong {
    font-weight: 700;
    color: var(--grey);
  }
  @media print {
    *,
    *:before,
    *:after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    abbr[title]:after {
      content: " (" attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    strong {
      font-family: var(--ff-heading);
      font-weight: 400 !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
      font-size: 30px;
    }
  }

  p,
  .air-p {
    margin-bottom: 1.3rem;
    line-height: 1.5;
    text-align: justify;
  }

  h1,
  .air-h1,
  h2,
  .air-h2,
  h3,
  .air-h3,
  h4,
  .air-h4 {
    margin: 1.414rem 0 0.5rem;

    line-height: 1.42;

    color: var(--grey);
  }

  h1,
  .air-h1 {
    margin-top: 0;
    font-size: 3.998rem;
  }

  h2,
  .air-h2 {
    font-size: 2.827rem;
  }

  h4,
  .air-h4 {
    font-size: 1.414rem;
  }

  h5,
  .air-h5 {
    font-size: 1.121rem;
  }

  h6,
  .air-h6 {
    font-size: 0.88rem;
  }

  small,
  .air-small {
    font-size: 0.707em;
  }

  img,
  canvas,
  iframe,
  video,
  svg,
  select,
  textarea {
    max-width: 100%;
  }

  body {
    font-weight: 400;
    margin: 6rem auto 1rem;

    text-align: center;
  }

  img .round {
    border-radius: 50%;
    height: 200px;
    margin: 0 auto;
    width: 200px;
  }

  a,
  a:visited {
    color: #3498db;
  }

  a:hover,
  a:focus,
  a:active {
    color: #2980b9;
  }

  pre {
    font-size: 2rem;
    background-color: #fafafa;
    padding: 1rem;
    text-align: left;
  }

  blockquote {
    margin: 0;
    border-left: 5px solid #7a7a7a;
    font-style: italic;
    padding: 1.33em;
    text-align: left;
  }

  p {
    color: var(--parablack);
  }
`

const Player = styled.div`
  margin-top: 2rem;
  position: relative;
  height: auto;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  margin-bottom: 2rem;
`

const blogTemplate = ({ pageContext }) => {
  const { blog } = pageContext
  //console.log(blog)

  return (
    <Layout relative={false}>
      <Seo title={blog.Title} />
      <ImageCover
        image={blog.MainMedia.localFile.childImageSharp?.gatsbyImageData}
        heading={blog.Title}
        section={"#main"}
        truedat={false}
        third
      />
      <Wrapper id="main" className="flex mx-auto justify-center">
        <div className="lg:max-w-[600px]">
          <Article>
            <ReactMarkdown
              source={blog.Content}
              transformImageUri={uri =>
                uri.startsWith("http")
                  ? uri
                  : `https://lafon.coeur.pw/uploads/${
                      uri.split("/uploads/")[1]
                    }`
              }
              className="articleContent"
              escapeHtml={false}
            />
            {blog.video_url ? (
              <Player id="lol">
                <ReactPlayer
                  className="react-player"
                  url={blog.video_url}
                  width="100%"
                  height="100%"
                />
              </Player>
            ) : null}
          </Article>
        </div>
        <BlogTemplateColumnData />
      </Wrapper>
    </Layout>
  )
}

export default blogTemplate

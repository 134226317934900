import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function BlogColumn(props) {
  return (
    <section className="w-[320px] mignon border-gray-700 border-t-4 shadow-md blog-column bg-gray-50 p-4 ml-12 mt-8 relative">
      <div className="sticky top-0 lg:p-4">
        <h2 className="lg:px-[24px] py-2 font-medium">Added Recently</h2>
        {props.data.allStrapiNewsBlog.nodes.map(e => (
          <Link to={`/news/${e.slug}/`} key={e.slug}>
            <p className="text-small py-6 border-b-2 border-gray-200">
              {e.Title.toLowerCase().charAt(0).toUpperCase() +
                e.Title.toLowerCase().slice(1)}
              ..
            </p>
          </Link>
        ))}
        <h2 className="py-2 lg:px-[24px] font-medium mt-8 text-right">
          Meet Bruno
        </h2>
        <div className="flex justify-center w-full">
          <GatsbyImage
            image={
              props.data.allStrapiAbout.nodes[0].TeamMember[0].Portrait
                .localFile.childImageSharp.gatsbyImageData
            }
            alt="Bruno Lafon from bruno lafon selection"
            className="mt-8 rounded-full"
          />
        </div>
        <p className="text-small py-6 text-justify ">
          {props.data.allStrapiAbout.nodes[0].TeamMember[0].Description.slice(
            0,
            239
          )}
          {".."}
          <br /> <br />
          <Link to={`/about-us/`}>
            <button className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none ">
              ABOUT US
            </button>
          </Link>
        </p>
      </div>
    </section>
  )
}

export default function BlogTemplateColumnData(props) {
  return (
    <StaticQuery
      query={graphql`
        query blogsColumnData {
          allStrapiNewsBlog(limit: 3, sort: { fields: strapiId, order: DESC }) {
            nodes {
              Title
              slug
            }
          }
          allStrapiAbout(
            filter: {
              TeamMember: { elemMatch: { FullName: { eq: "Bruno Lafon" } } }
            }
            limit: 1
          ) {
            nodes {
              TeamMember {
                FullName
                Description
                Portrait {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 200, height: 200, quality: 60)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <BlogColumn data={data} />}
    />
  )
}
